import '../App.css';
import React from 'react';
import { Steps, Col, Row, Image } from 'antd';  // ant design 相關
import { useNavigate } from 'react-router-dom';
import { StarFilled, LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

function Step3Page() {
  const navigate = useNavigate();
  const [position, setPosition] = React.useState(80);


  // 小企鵝騎機車動畫
  React.useEffect(() => {
    const timer = setTimeout(() => {
      setPosition(-80);
    }, -800);

    return () => clearTimeout(timer);
  }, []);

  // 小企鵝騎機車圖片
  const motorcycleImage = './motorcycle2.png';


  // style={{
  //   position: 'relative',
  //   left: `${position}%`,
  //   transition: 'left 4s ease-out', // 調整動畫持續時間和緩動效果
  // }}

  const getSkinType = () => {
    fetch(`${process.env.REACT_APP_BACKEND_URL}retrieve?id= + ${window.imageKey}`, {
      method: 'GET',
    })
    .then(response => {
      return response.json();
    }
    )
    .then(data => {
      window.skinTypeUrl = data.skinTypeImageUrl;
    }).then(data => {
      navigate(`${process.env.REACT_APP_PUBLIC_URL}/SharePage`);
    })
    .catch(error => {
      console.error('Error retrieving file:', error);
    }
    );
  };

  // 執行 getSkinType function
  getSkinType();
  
  return (
    <Col style={{ height:'100%'
                , width:'100%'
                , display: 'flex'
                , flexDirection: 'column'
                , alignItems: 'center'
                , justifyContent: 'center'}} >
      <Row style={{flex: 1, paddingTop:10, width:'100%', flexDirection: 'column' }}>
        <Steps
          // 手機板不自動轉為垂直模式
          responsive={false}
          items={[
            {
              // title: '上傳照片',
              status: 'finish',
            },
            {
              // title: '問券填寫',
              status: 'finish',
            },
            {
              // title: '完成照片分析',
              status: 'process',
              icon: <StarFilled style={{fontSize: 40, color: '#FFC700'}} />,
            },
          ]}
        />
        <Row  style={{ flex:1, width:'100%', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <p style={{fontSize: '24px'}}><b>Step 3</b></p>
            <p style={{fontSize: '24px'}}><b>檢測中...   請勿跳出畫面</b></p>
            {/* <p style={{fontSize: '18px'}}><b></b></p> */}
        </Row>
      </Row>
      {/* <Row style={{flex: 1, paddingTop:10, width:'100%', alignItems: 'center', justifyContent: 'center' }}>
        
      </Row> */}
      <Row style={{flex: 1, paddingTop:10, width:'100%', alignItems: 'center', justifyContent: 'center' }}>
        <Spin
          indicator={
            <LoadingOutlined
              style={{
                fontSize: 20,
                color: '#CC7B7B'
              }}/>
          }
        />
      </Row>

      <Row style={{flex: 1, paddingTop:10, paddingBottom:10, width:'80%', justifyContent: 'center'}}>
        <Image
          preview={false}
          width={250}
          src={motorcycleImage}
          style={{
            position: 'relative',
            left: `${position}%`,
            transition: 'left 6s ease-in-out',
          }}
        />
      </Row>
    </Col>
  );
}


export default Step3Page;
