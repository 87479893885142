import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import {  Button, Steps, Col, Row, Image } from 'antd';  // ant design 相關
import { StarFilled, CloudUploadOutlined } from '@ant-design/icons'; // ant design icon 相關

function Step1Page() {
  const [file, setFile] = useState(null);
  const fileInputRef = useRef(null);
  const [base64String, setBase64String] = useState(null);
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const [isUpload, setIsUpload] = useState(false);

  const navigate = useNavigate();
  
  const handleClick = async () => {
    try {
      // 上傳圖片
      handleUpload()
    } catch (error) {
      console.error('上傳圖片失敗:', error);
      // 顯示錯誤訊息給使用者
      alert('上傳圖片時發生錯誤，請稍後再試。');
    }
  };

  // 上傳圖片(先放在前端呈現)
  const handleFileChange = (event) => {

    // 沒有上傳照片就 reutrn 掉
    if(event===null || event.target === null || event.target.files===null || !event.target.files[0]){
      return 
    }

    const selectedFile = event.target.files[0];
    setFile(selectedFile);

    // 將檔案轉換為 base64 編碼
    const reader = new FileReader();
    reader.onload = () => {
      setBase64String(reader.result)
    };
    reader.readAsDataURL(selectedFile);
  };

  // 打上傳檔案 API
  const handleUpload = () => {
    const formData = new FormData();
    formData.append('file', file);

    // 確認照片按鈕 disable
    setIsUpload(true);
  
    // 使用 fetch API 或 Axios 等函式庫發送 POST 請求上傳檔案
    fetch(`${process.env.REACT_APP_BACKEND_URL}upload`, {
      method: 'POST',
      body: formData
    })
    .then(response => {
      // 切換回上傳照片按鈕
      setIsUpload(false);

      if (response.ok) {
        return response.json();
      } else {
        throw new Error('Request failed');
      }
    })
    .then(data => {
      window.imageKey = data.id;
      // 導向下一個頁面
      navigate(`${process.env.REACT_APP_PUBLIC_URL}/step2Page`);
    })
    .catch(error => {
    });
  }

  return (
      <Col style={{ flex:1, height:'100%'
                  , width:'100%'
                  , display: 'flex'
                  , flexDirection: 'column'
                  , alignItems: 'center'
                  , justifyContent: 'center'}} >
        {/* 上傳照片 loding 提示 */}

        <input
          type="file"
          name="file"
          onChange={handleFileChange}
          style={{ display: 'none' }}
          ref={fileInputRef}
        />
        <Row  style={{ flex:1, paddingTop:10, flexDirection: 'column', width:'100%'}}>    
          <Steps
            // 手機板不自動轉為垂直模式
            responsive={false}
            items={[
              {
                // title: '上傳照片',
                status: 'process',
                icon: <StarFilled style={{fontSize: 40, color: '#FFC700'}} />,
              },
              {
                // title: '問券填寫',
                status: 'wait',
              },
              {
                // title: '完成照片分析',
                status: 'wait',
                icon: '',
              },
            ]}
          />
           <Row  style={{ flex:1, width:'100%', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <p style={{fontSize: '24px'}}><b>Step 1</b></p>
            <p style={{fontSize: '24px'}}><b>請拍攝或上傳照片</b></p>
          </Row>
        </Row>
       
        <Row style={{ flex:1, paddingTop:10, width:'100%' }}>
            <div style={{
              height:'100%', 
              width:'100%',
              border: '2px dashed #cccccc',
              backgroundColor: 'rgba(240, 239, 239, 0.49)',
              borderRadius: '20px',
              padding: '20px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column'
            }}>
              {base64String===null || base64String==='' ?
              <>
                <CloudUploadOutlined style={{fontSize: '100px', paddingTop:10, paddingBottom:10 }} />
                <Button 
                    type="primary"
                    style={{ flex:1, fontSize: '20px', marginTop:50, marginBottom:10, backgroundColor: '#CC7B7B', color: 'white' }}
                    onClick={() => fileInputRef.current.click()} >
                      <b>選擇上傳</b>
                  </Button>
                </>
                :
                <Image
                  src={`${base64String}`}
                  alt="Uploaded file"
                  style={{

                    objectFit: 'contain'
                  }}
                  preview={false}
                  width='100%'
                  height={300}
                  fit="contain"
                  onLoad={() => setIsImageLoaded(true)}
                />
              }
            </div>
        </Row>

        <Row style={{flex: 1, paddingTop:10, paddingBottom:10, width:'100%', justifyContent: 'center'}}>
          {base64String===null || base64String==='' ? <div style={{minHeight:'50px'}}></div> :
          <>
            <Button 
                type="primary"
                style={{ fontSize: '20px', marginTop:50, marginRight:10, backgroundColor: '#CC7B7B', color: 'white', minWidth:'15vw' }}
                onClick={() => fileInputRef.current.click()}>
              <b>重新上傳</b>
            </Button>
            {isUpload ? 
            <Button type="primary" 
              style={{ fontSize: '20px', marginTop:50, backgroundColor: '#CC7B7B', color: 'white', minWidth:'15vw' }}
              loading iconPosition={'start'}>
              上傳中
            </Button>
            :
            <Button 
                type="primary"
                style={{ fontSize: '20px', marginTop:50, backgroundColor: '#CC7B7B', color: 'white', minWidth:'15vw' }}
                onClick={handleClick}>
              <b>確認相片</b>
            </Button>}
          </>}
        </Row> 
      </Col>
  );
}

export default Step1Page;
