import './App.css';
import React, {useEffect, useState} from 'react';
import useRWD from './jsx/UseRWD.jsx';
import MainArea from './jsx/MainArea.jsx'
import Step1Page from './jsx/Step1Page.jsx'
import Step2Page from './jsx/Step2Page.jsx'
import Step3Page from './jsx/Step3Page.jsx'
import SharePage from './jsx/SharePage.jsx'
import { Routes, Route, useLocation, useNavigate} from 'react-router-dom';
import {  Row, Layout, Flex, Image, Col } from 'antd';  // ant design 相關


const { Header, Content } = Layout;


const logo = './pancoLogo.png';

const App=()=>{

    // 抓取 RWD 當下設備
    window.useDevice = useRWD(); 
    const navigate = useNavigate();
    const location = useLocation();
    // window.headerColor
    const [headerColor, setHeaderColor] = useState('#FFF9EF');
    // window.backgroundColor
    const [backgroundColor, setBackgroundColor] = useState('#F3DFE1');


    
    useEffect(() => {

      console.log('location.pathname', location.pathname);

      // 如果是 "/sharePage" header 跟 background 交換 使用兩個變數來控制  這段用hook 讓畫面重新渲染
      if(location.pathname === `${process.env.REACT_APP_PUBLIC_URL}/SharePage`){
        setHeaderColor('#F3DFE1');
        setBackgroundColor('#FFF9EF');
      } else {
        setHeaderColor('#FFF9EF');
        setBackgroundColor('#F3DFE1');
      }



      // 在組件初次渲染時檢查路徑
      if (location.pathname === `${process.env.REACT_APP_PUBLIC_URL}`) {
        navigate(`${process.env.REACT_APP_PUBLIC_URL}/`);
      }


    }, [navigate, location.pathname]);

    const handleClickToHome = () => {
      navigate(`${process.env.REACT_APP_PUBLIC_URL}/`);
    };
    
    // 根據網址 routes 代碼
    const routerContent = (
      <Routes flex="1">
        <Route path={`${process.env.REACT_APP_PUBLIC_URL}/`} element={<Row span={24} style={{ alignItems: 'center', justifyContent: 'center', height:'100%'}}><MainArea span={24} /></Row>} />
        <Route path={`${process.env.REACT_APP_PUBLIC_URL}/step1Page`} element={<Row span={24} style={{ alignItems: 'center', justifyContent: 'center', height:'100%'}} ><Step1Page span={24} /></Row>} />
        <Route path={`${process.env.REACT_APP_PUBLIC_URL}/step2Page`} element={<Row span={24} style={{ alignItems: 'center', justifyContent: 'center', height:'100%'}} ><Step2Page span={24} /></Row>} />
        <Route path={`${process.env.REACT_APP_PUBLIC_URL}/step3Page`} element={<Row span={24} style={{ alignItems: 'center', justifyContent: 'center', height:'100%'}} ><Step3Page span={24} /></Row>} />
        <Route path={`${process.env.REACT_APP_PUBLIC_URL}/sharePage`} element={<Row span={24} style={{ alignItems: 'center', justifyContent: 'center', height:'100%'}} ><SharePage span={24} /></Row>} />
      </Routes>
    );
    
    return (
      <Flex vertical='vertical' style={{
        minHeight: '100vh',
        // height:'100vh',
        width:'100vw',
        // window.headerColor
        backgroundColor: backgroundColor, 
        // backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center'
      }}>
        {/* header */}
        <Header span='24' style={{ paddingRight:0, paddingLeft:0, height:60}}>
          {/* // window.backgroundColor */}
          <div className='flex justify-center items-center' span='24' style={{ backgroundColor: headerColor}}>
            {/* 圖片放大功能需要另外設定關閉 preview={false} */}
            <Image src={logo} style={{ height: 20, maxWidth:120, marginTop:25, marginBottom:15 , cursor: 'pointer' }} preview={false} onClick={handleClickToHome} />
          </div>
        </Header>

        {/* 主要切版，分為 電腦/手機 兩種版面 */}
        <Content className="overflow-x-hidden" style={{height:'100%', width:'100%'}}>
          {window.useDevice === 'PC' ?
            <Col span='12' offset={6} style={{height:'100%', width:'100%'}}>
              {routerContent}
            </Col>
          : 
            <Col span='22' offset={1} style={{height:'100%', width:'100%'}}>
              {routerContent}
          </Col>}
        </Content>
      </Flex>
    )
};

export default App; 