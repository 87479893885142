import React from 'react';
import {  Button, Image, Col, Row, Carousel } from 'antd';  // ant design 相關
import { useNavigate } from 'react-router-dom';
import { RightCircleFilled } from '@ant-design/icons'; // ant design icon 相關

const bannerImage = './BannerImage.png';
const bannerImage1 = './BannerImage01.jpg';
const bannerImage2 = './BannerImage02.jpg';
const bannerImage3 = './BannerImage03.jpg';
const textImage = './text2.png';
const penguinImage = './penguin.png';

const MainArea = () => {

  const navigate = useNavigate();
  const handleClick = () => {
    navigate(`${process.env.REACT_APP_PUBLIC_URL}/step1Page`);
  };

  return (
      <Col style={{ 
                  height:'100%'
                  , width:'100%'
                  , display: 'flex'
                  , flexDirection: 'column'
                  , alignItems: 'center'
                  , justifyContent: 'center'
                  , margin: '3px'
                  }} >
                    

        {window.useDevice==='PC'?
          <>
            <Row className='h-1/8 w-2/12, text-center'>
              {/* <Image preview={false} src={bannerImage} /> */}
              <Carousel autoplay style={{ minWidth:'10px', maxWidth:'30vw' }} >
                <div>
                  <Image preview={false} src={bannerImage1} />
                </div>
                <div>
                  <Image preview={false} src={bannerImage2} />
                </div>
                <div>
                  <Image preview={false} src={bannerImage3} />
                </div>
              </Carousel>
              
            </Row>
            <Row className='max-h-16 min-h-8 h-1/6' span={24} style={{ width:'50%', flex:1}}>
              <Image preview={false} src={textImage} />
              {/* <p style={{ height:'100%' }}>職場叢林中的<br/>肌膚保養藥方!</p> */}
            </Row>
            <Row className='max-h-50 min-h-10 h-1/6' span={24} style={{ width:'35%', flex:1}}>
              <Image preview={false} src={penguinImage} />
            </Row>
            <Row className='flex items-center justify-center w-full' style={{ flex:1, paddingBottom:10, paddingTop:10}}>
              <button style={{ backgroundColor: '#CC7B7B', minWidth:'30vw' }} onClick={handleClick} className=" text-white font-bold w-1/2 h-8 md:w-1/8 md:h-12 text-2xl md:text-4xl lg:text-lg rounded">
                    我要檢測 <RightCircleFilled />
              </button>
            </Row>
          </>
        :
        <>
            <Row className='h-1/8 w-2/12, text-center'>
              {/* <Image preview={false} src={bannerImage} /> */}
              <Carousel autoplay style={{ minWidth:'10px', maxWidth:'88vw' }} >
                <div>
                  <Image preview={false} src={bannerImage1} />
                </div>
                <div>
                  <Image preview={false} src={bannerImage2} />
                </div>
                <div>
                  <Image preview={false} src={bannerImage3} />
                </div>
              </Carousel>
              
            </Row>
            <Row className='max-h-24' span={24} style={{ width:'80%', flex:1, marginTop:'5px'}}>
            <Image preview={false} src={textImage} />
              {/* <p style={{ height:'100%' }}>職場叢林中的<br/>肌膚保養藥方!</p> */}
            </Row>
            <Row span={24} style={{ width:'80%', flex:1}}>
              <Image preview={false} src={penguinImage} />
            </Row>
            <Row className='flex items-center justify-center w-full' style={{ flex:1, paddingBottom:10, paddingTop:10}}>
              <button style={{ backgroundColor: '#CC7B7B', minWidth:'80vw' }} onClick={handleClick} className=" text-white font-bold w-1/2 h-8 md:w-1/8 md:h-12 text-2xl md:text-4xl lg:text-lg rounded">
                    我要檢測 <RightCircleFilled />
              </button>
            </Row>
          </>}
      </Col>
  );
};

export default MainArea;
