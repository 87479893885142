import React from 'react';
import {  Button, Image, Col, Row } from 'antd';  // ant design 相關
import { useNavigate } from 'react-router-dom';

function SharePage() {

  // 取得檢測結果
  const skinTypeUrl = window.skinTypeUrl;

  const navigate = useNavigate();


  const handleClick = () => {
    navigate(`${process.env.REACT_APP_PUBLIC_URL}/`);
  };

  const shareOnFacebook = () => {

    let fbShareUrl = '';

    switch (skinTypeUrl) {
      case process.env.REACT_APP_SKIN_TYPE_A_S3_URL :
        fbShareUrl = process.env.REACT_APP_SKIN_TYPE_A_FB_SHARE_URL;
        break;
      case process.env.REACT_APP_SKIN_TYPE_B_S3_URL :
        fbShareUrl = process.env.REACT_APP_SKIN_TYPE_B_FB_SHARE_URL;
        break;
      case process.env.REACT_APP_SKIN_TYPE_C_S3_URL :
        fbShareUrl = process.env.REACT_APP_SKIN_TYPE_C_FB_SHARE_URL;
        break;
      case process.env.REACT_APP_SKIN_TYPE_D_S3_URL :
        fbShareUrl = process.env.REACT_APP_SKIN_TYPE_D_FB_SHARE_URL;
        break;
      case process.env.REACT_APP_SKIN_TYPE_E_S3_URL :
        fbShareUrl = process.env.REACT_APP_SKIN_TYPE_E_FB_SHARE_URL;
        break;
      default:
        break;
    }
    window.open(fbShareUrl, '_blank');
  }

  return (
    <>
        {window.useDevice==='PC'?

          // 電腦版面
          <Col style={{ height:'80%'
            , width:'100%'
            , maxHeight:'80%'
            , display: 'flex'
            , flexDirection: 'column'
            , alignItems: 'center'
            , justifyContent: 'center'}} >
      
            <Row style={{ maxWidth:'45%', maxHeight:'85vh', flex: 3, marginTop:1, flexDirection: 'column', alignItems: 'center' }}>
              <Image
                style={{  objectFit: 'contain' }}
                preview={false}
                src={skinTypeUrl}
                placeholder="Loading..."
                alt="My Image"
              />
      
            </Row>
            <Row style={{ marginTop:'10px', width:'100%', justifyContent: 'center'}}>
              <Button 
                  type="primary"
                  style={{ fontSize: '20px', marginRight:10, backgroundColor: '#CC7B7B', minWidth:'10vw' }}
                  onClick={handleClick}>
                <b>回首頁</b>
              </Button>
              <Button 
                  type="primary"
                  style={{ fontSize: '20px', backgroundColor: '#CC7B7B' , minWidth:'10vw'}} 
                  onClick={() => shareOnFacebook()}>
      
                    {/* 根據 skinTypeUrl 判斷，要呈現的超連結網址  */}
                    <b>分享</b>
              </Button>
            </Row>
          </Col>
      :
      // 手機版面
      <Col style={{
        display: 'flex'
        , flexDirection: 'column'
        , alignItems: 'center'
        // , justifyContent: 'center'
        }} >
  
        {/* <Row style={{ maxWidth:'75vw', minWidth:'80vw', maxHeight:'73vh', minHeight:'73vh', marginTop:1, flexDirection: 'column', alignItems: 'center' }}> */}
        <Row style={{ marginTop:1, flexDirection: 'column', alignItems: 'flex-start' }}>  
          <Image
            height={ window.innerHeight * 0.83}
            style={{objectFit: 'contain' }}
            preview={false}
            src={skinTypeUrl}
            placeholder="Loading..."
            alt="My Image"
          />

        </Row>
        <Row style={{ marginTop:'10px', lex: 1, width:'100%', justifyContent: 'center'}}>
          <Button 
              type="primary"
              style={{ fontSize: '20px', marginRight:10, backgroundColor: '#CC7B7B', minWidth:'30vw' }}
              onClick={handleClick}>
            <b>回首頁</b>
          </Button>
          <Button 
              type="primary"
              style={{ fontSize: '20px', backgroundColor: '#CC7B7B', minWidth:'30vw' }} >
                {/* 根據 skinTypeUrl 判斷，要呈現的超連結網址  */}
                <div type="primary" style={{ fontSize: '20px', backgroundColor: '#CC7B7B' }} onClick={() => shareOnFacebook()}>
                  <b>分享</b>
                </div>
          </Button>
        </Row>
      </Col>
      }
    </>
  );
}

export default SharePage;
