import '../App.css';
import React, { useState, useEffect } from 'react';
import {  message, Steps, Col, Row, Radio } from 'antd';  // ant design 相關
import { useNavigate } from 'react-router-dom';
import { StarFilled, RightCircleFilled } from '@ant-design/icons'; // ant design icon 相關

// 全局配置 message 組件
message.config({
  top: '50px',
  placement: 'topRight',
  maxCount: 3,
});

// 使用 message 組件
const showErrorMessage = () => {
  message.error('請選擇所有問題的答案，再點選送出問券');
};

function Step2Page() {
  const [a1, setA1] = useState(null);
  const [a2, setA2] = useState(null);
  const [a3, setA3] = useState(null);
  const [a4, setA4] = useState(null);
  const [paddingLeftValue, setPaddingLeftValue] = useState('12%');
 


  useEffect(() => {
    if (window.useDevice === 'PC') {
      setPaddingLeftValue('25%');
    } else {
      setPaddingLeftValue('12%');
    }
  }, [window.useDevice]);


  // 跳轉道 step3
  const navigate = useNavigate();

  const handleClick = () => {

    // 如果沒有填寫完成問券，則顯示錯誤訊息
    if (a1 === null || a2 === null || a3 === null || a4 === null) {
      showErrorMessage();
      return;
    }

    const requestBody = {
      answers: {
        answer1: a1,
        answer2: a2,
        answer3: a3,
        answer4: a4
      },
      image_id: window.imageKey
    };

    // 使用 fetch API 或 Axios 等函式庫發送 POST 請求上傳檔案
    fetch(`${process.env.REACT_APP_BACKEND_URL}update`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(requestBody)
    }).then(response => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error('Request failed');
      }
    }).then(data =>  {
      navigate(`${process.env.REACT_APP_PUBLIC_URL}/step3Page`);
    })
    .catch(error => {
      // 顯示錯誤訊息給使用者
      // alert('送出問券時發生錯誤，請稍後再試。');
      

      console.error('Error uploading file:', error);
    });
  };

  return (
    <Col style={{ height:'100%'
                , width:'100%'
                , display: 'flex'
                , flexDirection: 'column'
                , alignItems: 'left'
                , justifyContent: 'left'}} >
      
      <Row style={{flex: 1, paddingTop:10, width:'100%', flexDirection: 'column' }}>
        <Steps
          // 手機板不自動轉為垂直模式
          responsive={false}
          items={[
            {
              // title: '上傳照片',
              status: 'finish',
            },
            {
              // title: '問券填寫',
              status: 'process',
              icon: <StarFilled style={{fontSize: 40, color: '#FFC700'}} />,
            },
            {
              // title: '完成照片分析',
              status: 'wait',
              icon: '',
            },
          ]}
        />  
        <Row  style={{ flex:1, width:'100%', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <p style={{fontSize: '24px'}}><b>Step 2</b></p>
            <p style={{fontSize: '24px'}}><b>請回答以下問題</b></p>
        </Row>
      </Row>
      <Row style={{ fontSize: '18px', flex: 1, width:'100%', paddingLeft:paddingLeftValue, flexDirection: 'column', alignItems: 'left', justifyContent: 'left' }}>
          <b style={{marginBottom:4}}>Q1：你的性別?</b>
          <Radio.Group value={a1} onChange={(e) => setA1(e.target.value)} optionType="button" buttonStyle="solid">
            <Radio.Button style={{ fontSize: '18px' }} value="MALE">男性</Radio.Button>
            <Radio.Button style={{ fontSize: '18px' }} value="FEMALE">女性</Radio.Button>
          </Radio.Group>
      </Row>
      <Row style={{fontSize: '18px', flex: 1, paddingTop:15, paddingLeft:paddingLeftValue, width:'100%', flexDirection: 'column', alignItems: 'left', justifyContent: 'left' }}>
          <b style={{marginBottom:4}}>Q2：你的年齡?</b>
          <Radio.Group value={a2} onChange={(e) => setA2(e.target.value)} optionType="button" buttonStyle="solid">
            <Radio.Button style={{ fontSize: '18px' }} value="UNDER25">25以下</Radio.Button>
            <Radio.Button style={{ fontSize: '18px' }} value="BETWEEN25AND35">25-35</Radio.Button>
            <Radio.Button style={{ fontSize: '18px' }} value="OVER36">36以上</Radio.Button>
          </Radio.Group>
      </Row>
      <Row style={{fontSize: '18px', flex: 1, paddingTop:15, paddingLeft:paddingLeftValue, width:'100%', flexDirection: 'column', alignItems: 'left', justifyContent: 'left' }}>
          <b style={{marginBottom:4}}>Q3：你最在意保養的哪一道程序?</b>
          <Radio.Group value={a3} onChange={(e) => setA3(e.target.value)} optionType="button" buttonStyle="solid">
            <Radio.Button style={{ fontSize: '18px' }} value="TONER">化妝水</Radio.Button>
            <Radio.Button style={{ fontSize: '18px' }} value="SERUM">精華液</Radio.Button>
            <Radio.Button style={{ fontSize: '18px' }} value="MOISTURIZER">乳液或乳霜</Radio.Button>
          </Radio.Group>
      </Row>
      <Row style={{fontSize: '18px', flex: 1, paddingTop:15, paddingLeft:paddingLeftValue , width:'100%', flexDirection: 'column', alignItems: 'left', justifyContent: 'left' }}>
          <b style={{marginBottom:4}}>Q4：你最在意肌膚的什麼問題?</b>
          <Radio.Group value={a4} onChange={(e) => setA4(e.target.value)} optionType="button" buttonStyle="solid">
            <Radio.Button style={{ fontSize: '18px' }} value="DULLNESS">暗沈泛黃</Radio.Button>
            <Radio.Button style={{ fontSize: '18px' }} value="ACNE">痘痘粉刺</Radio.Button>
            <Radio.Button style={{ fontSize: '18px' }} value="DRYNESS">乾燥脫皮</Radio.Button>
          </Radio.Group>
          <Radio.Group style={{paddingTop:4}} value={a4} onChange={(e) => setA4(e.target.value)} optionType="button" buttonStyle="solid">
            <Radio.Button style={{ fontSize: '18px' }} value="OILINESS">出油旺盛</Radio.Button>
            <Radio.Button style={{ fontSize: '18px' }} value="WRINKLES">細紋鬆垮</Radio.Button>
          </Radio.Group>
      </Row>
      <Row style={{flex: 1, paddingTop:30, paddingBottom:10, width:'100%', justifyContent: 'center'}}>
        {/* <Button 
          type="primary"
          style={{ fontSize: '24px', backgroundColor: '#CC7B7B'}}
          onClick={handleClick}>
            <b>送出問券</b>
            <RightCircleFilled />
        </Button> */}
          <button style={{ backgroundColor: '#CC7B7B', minWidth:'100wv' }} onClick={handleClick}  className=" text-white font-bold w-1/2 h-8 md:w-1/8 md:h-12 text-2xl  md:text-4xl lg:text-lg rounded">
              送出問券 <RightCircleFilled />
          </button>
      </Row>
    </Col>
  );
}

export default Step2Page;
