import { useState, useEffect} from 'react';

const useRWD=()=>{
    const [mobile,setMobile]=useState("mobile");
    const [pageTitle, setPageTitle] = useState('panco all of you');

    // 目前介面模式顯示在瀏覽器分頁中 電腦/手機
    useEffect(() => {
        document.title = pageTitle;
    }, [pageTitle]);

    const handleRWD=()=>{
        if(window.innerWidth>1200){
            setMobile("PC");
        }
        // 只區分電腦和手機
        // else if (window.innerWidth>600)
        //     setMobile("tablet");
        else{
            setMobile("mobile");
        }
    }

    useEffect(()=>{
    
        window.addEventListener('resize',handleRWD);
        handleRWD(); //加入此行
        
        return(()=>{
            window.removeEventListener('resize',handleRWD);
        })
    },[]);

    return mobile;
}

export default useRWD;